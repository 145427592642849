.pagination{
    border-radius: 0px;
    align-items: center;
}

.page-item:first-child .page-link{
    border-radius: 0px;
}

.page-item:last-child .page-link{
    border-radius: 0px;
}

.page-link{
    @extend .display-2;
    background-color: transparent;
    color: $color-gray;
    border:1px solid transparent;
    line-height: 1;
    
}

.page-link-nav{
    border-color: $color-gray;
    padding: 12px 14px;
    margin-left: 1px;
    margin-right: 1px;
}

.page-link-nav [class^="icon"]{
    font-size: 8px;
    line-height: 1;
}

.page-link:focus, .page-link:hover{
    background-color: transparent;
    color: $color-hover;
    border-color: transparent;
}

.page-link-nav:focus, .page-link-nav:hover{
    background-color: transparent;
    color: $color-white;
    border-color: $color-hover;
}