
/* 
Created on: 17 Sep, 2014, 4:29:37 PM
Author: senthil
*/

.ti_tx,
.mi_tx,
.mer_tx {
	width: 100%;
	text-align: center;
	margin: 10px 0;
}

.time,
.mins,
.meridian {
	width: 60px;
	float: left;
	margin: 0 10px;
	font-size: 20px;
	color: #2d2e2e;
	font-family: arial;
	font-weight: 700;
}

.prev,
.next {
	cursor: pointer;
	padding: 18px;
	width: 28%;
	border: 1px solid #ccc;
	margin: auto;
	background: url(../img/arrow.png) no-repeat;
	border-radius: 5px;
}

.prev:hover,
.next:hover {
	background-color: #ccc;
}

.next {
	background-position: 50% 150%;
}

.prev {
	background-position: 50% -50%;
}

.time_pick {
	position: relative;
}

.timepicker_wrap {
	padding: 10px;
	border-radius: 5px;
	z-index: 998;
	display: none;
	box-shadow: 2px 2px 5px 0 rgba(50,50,50,0.35);
	background: #f6f6f6;
	border: 1px solid #ccc;
	float: left;
	position: absolute;
	top: 27px;
	left: 0;
}

.arrow_top {
	position: absolute;
	top: -10px;
	left: 20px;
	background: url(../img/top_arr.png) no-repeat;
	width: 18px;
	height: 10px;
	z-index: 999;
}
input.timepicki-input {
	background: none repeat scroll 0 0 #FFFFFF;
    	border: 1px solid #CCCCCC;
    	border-radius: 5px 5px 5px 5px;
    	float: none;
    	margin: 0;
    	text-align: center;
    	width: 70%;
}
a.reset_time {
	float: left;
	margin-top: 5px;
	color: #000;
}