a,
a:focus,
a:hover,
a:active,
button,
button:hover,
button:focus,
button:active {
    text-decoration: none;
    outline: none;
}

a {
    color: $color-gray;
}

a:focus,
a:hover,
a:active {
    color: $color-hover;
}

img{
    max-width: 100%;
}

.highlight{
    color: $color-hover;
}


.image-block{
    width: 100%;
    display: block;
}

.divider{
    border-top: 1px solid $color-border;
    margin: 1rem 0;
}

//
// Row
//

.row-pull {
    margin-left: -35px;
    margin-right: -35px;
}

.form-row {
    margin-left: -10px;
    margin-right: -10px;
}

.form-row>.col,
.form-row>[class*="col-"] {
    padding-right: 10px;
    padding-left: 10px;
}

.avatar{
    width: 45px;
    height: 41px;
    border: 2px solid #fff;
    background-color: #dedede;
    background-size: cover;
    background-position: 50%;
}

.treeview-container{
    display: flex;
    flex-wrap: wrap;
    margin: 1.5rem 0;
}

//
// Page login
//
.page-login{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    position: relative;

    &:before {
        content: "";
        position: fixed;
        top: 30px;
        bottom: 30px;
        right: 0;
        left: 0px;
        background-image: url(../img/comup.png);
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: contain;
        opacity: 0.05;
        z-index: -1;
    }

    .row{
        width: 100%;
    }
}

.login-wrapper{
    width: 100%;
    max-width: 460px;
}

.page-login-2 .login-wrapper{
    margin-top: 4vw;
}

//
// Copyright
//
.copyright{
    display: flex;
    align-items: center;
    justify-content: space-between;

    p{
        margin: 0;
    }
}

.copyright-abs{
    position: absolute;
    right: 0px;
    bottom: 30px;

    img{
        margin-right: 1rem;
    }
}



ul.treeview, ul.treeview ul{
    list-style: none;
}

.sidebar-minimized{
    .hide-on-minimized{
        display: none;
    }
}


//
// Preloader
//


.app-preloader{
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #111515;
    z-index: 99999;
    display: flex;
    align-items: center;
    justify-content: center;
}




.loader,
.loader:after {
  border-radius: 50%;
  width: 60px;
  height: 60px;
}
.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 8px solid rgba(255, 255, 255, 0.2);
  border-right: 8px solid rgba(255, 255, 255, 0.2);
  border-bottom: 8px solid rgba(255, 255, 255, 0.2);
  border-left: 8px solid #ffffff;
  transform: translateZ(0);
  animation: load8 1.1s infinite linear;
}
@keyframes load8 {
  0% {
   transform: rotate(0deg);
  }
  100% {
   transform: rotate(360deg);
  }
}




