.breadcrumb {
    background-color: transparent;
    padding: 20px 0px;
}

.breadcrumb-item,
.breadcrumb-item a {
    color: $color-gray;
    @extend .display-2;
}

.breadcrumb-item.active {
    color: $color-hover;
}

.breadcrumb-item+.breadcrumb-item::before {
    content: "-";
}

.breadcrumb-item a:hover {
    color: $color-white;
}


@include media-breakpoint-down(sm) {
    .breadcrumb{
        padding-bottom: 10px;
        margin-bottom: 0;
    }
}