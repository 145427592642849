.sidebar {
    display: flex;
    flex-direction: column;
    padding: $sidebar-padding;
    color: $sidebar-color;
    background: $sidebar-background;
    box-shadow: $sidebar-shadow;
}

*{
    box-sizing: border-box;
}


.sidebar-nav {
    position: relative;
    flex: 1;
    
    .nav {
        flex-direction: column;
        min-height: 100%;
    }

    .nav > .nav-item{
        border-bottom: 1px solid #1d2121;
    }

    .nav > .nav-item:last-of-type {
        border-bottom: none !important;
    }
    .nav > .nav-item.open, .nav > .nav-item:last-of-type{
        border-color: transparent;
    }
    
    .nav-item {
        position: relative;
        margin: 0;
        transition: $transition-base;
       
        
    }
    .nav-link {
        display: block;
        position: relative;
        text-decoration: none;
        padding: $nav-link-padding-y $nav-link-padding-x;
        padding-left: $sidebar-left-space;
        background-color: transparent;
        text-decoration: none;
        font-size: 14px;
        color: #fff;
        height: 60px;
    }

    .nav-link  i{
        font-size: 26px;
        position: absolute;
        left: $nav-link-padding-x;
        top: 50%;
        transform: translateY(-50%);
        color: $color-gray;
    }

    .nav-link:hover, .nav-link:hover i{
        color: $color-hover;
    }

    .nav-link-dropdown {
        padding-left: $nav-link-padding-x;
        background-color: $bg-primary;
        i{
            position: absolute;
            left: auto;
            right: 20px;
            font-size: 8px;
            color: #fff;
            transform: translateY(-50%) rotate(0deg);
        }
    }

    .nav-dropdown-items {
        max-height: 0;
        padding: 0;
        margin: 0;
        overflow-y: hidden;
        transition: max-height .3s ease-in-out;
        .nav-item {
            padding: 0;
            list-style: none;
        }
    }
    .nav-dropdown-items .nav-dropdown-items{
        position: relative;
        background-color: #161c1c;
        &:after{
            content: "";
            position: absolute;
            left: 45px;
            top: 20px;
            bottom: 20px;
            width: 1px;
            background-color: #464444;
        }

        .nav-link{
            padding: 10px 30px;
            padding-left: $sidebar-left-space;
            height: auto;
            
            &:after{
                content: "";
                position: absolute;
                left: 42px;
                top: 50%;
                transform: translateY(-50%);
                width: 7px;
                height: 7px;
                border-radius: 50%;
                background-color: #464444;
                z-index: 4;
            }
        }

        .nav-link:hover:after{
            background-color: $color-hover;
        }
    }
    
    .nav-item.open > .nav-dropdown-items{
        max-height: 1000px;
    }
    .open .nav-dropdown-toggle i{
        transform: translateY(-50%) rotate(180deg);
    }

    .nav-dropdown-items .nav-item.open{
        background-color: $bg-primary;
        
        > .nav-link,  > .nav-link i{
            color: $color-hover;
        }
    }
}


@include media-breakpoint-up(xl) {
    .sidebar-minimized {
        .sidebar {
            .sidebar-nav .nav-item.open .nav-dropdown-items{
                overflow-y: visible;
            }
            .menu-title{
                position: absolute;
                left: 80px;
                top: 0;
                bottom: 0;
                background-color: #111515;
                width: 270px;
                padding: 20px 25px;
                opacity: 0;
                transform: translateX(-100%);
                text-align: left;
               
            }

            .nav-link{
                padding: 0;
                width: 80px;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .nav-item:hover > .nav-link > .menu-title{
                opacity: 1;
                transform: translateX(0%);
                box-shadow: -1px -1px 14px rgba(0, 0, 0, 0.25), 1px 1px 14px rgba(0, 0, 0, 0.25);
            }
            .nav-link:hover, .nav-item:hover > .nav-link{
                background-color: #111515;
            }

            .sidebar-nav .nav-link-dropdown i{
                left: 50%;
                top: auto;
                right: auto;
                margin-left: -4px;
                bottom:4px;
            }

            .sidebar-nav .nav-dropdown-items .nav-dropdown-items{
                width: 270px;
                position: absolute;
                left: 80px;
                top: 60px;
                max-height: 1000px;
                opacity: 0;
                visibility: hidden;

                .nav-link{
                    width: 100%;
                    justify-content: flex-start;
                }

                .nav-link:hover{
                    background-color: transparent;
                }
            }
            li.nav-item:hover > ul.nav-dropdown-items{
                opacity: 1;
                visibility: visible;
            }
        }
    }
}

.mCS-rounded-dots.mCSB_scrollTools .mCSB_draggerRail, .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail{
    background-image: none;
    background-color: transparent;
}

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{
    border-radius: 0px;
}
.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar{
    background-color: #2b3737;
}

.mCSB_scrollTools .mCSB_draggerContainer{
    background-color: #080a0a;
}
.mCS-rounded.mCSB_scrollTools .mCSB_dragger, .mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger, .mCS-rounded-dots.mCSB_scrollTools .mCSB_dragger, .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger{
    height: 80px;
}

.mCSB_inside > .mCSB_container{
    margin-right: 14px;
}

#sidebar {
    .mCustomScrollBox{
        overflow: visible;
    }

    .mCSB_container{
        overflow: visible;
    }

    .mCSB_inside > .mCSB_container{
        margin-right: 0;
    }

    .mCSB_scrollTools{
        opacity: 0!important;
        z-index: -1;
    }
}


@include media-breakpoint-down(lg) {
    .sidebar-nav {
        .nav-link {
            padding: $nav-link-padding-y $nav-link-padding-x;
            padding-left: 65px;
            font-size: 12px;
            height: 60px;
        }
    
        
        
        .nav-dropdown-items .nav-dropdown-items{
            .nav-link{
                padding: 10px 30px;
                padding-left: 65px;
            }
        }
    }
}


