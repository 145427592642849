body {
    background-color: $body-background;
    padding: $body-padding;
    margin: $body-margin;
}

.app {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    position: relative;
}

.app-header {
    flex: 0 0 $header-height;
    height: $header-height;
}

.app-footer {
    flex: 0 0 $footer-height;
}

.app-body {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    overflow-x: hidden;
    .main {
        flex: 1;
        min-width: 0;
        transition: $transition-base;
    }
    .sidebar {
        // $sidebar-width is the width of the columns
        flex: 0 0 $sidebar-width; // put the nav on the left
        order: -1;
    }
}

//
// header
//
.header-fixed {
    .app-header {
        position: fixed;
        z-index: $zindex-sticky;
        width: 100%;
    }
    .app-body {
        margin-top: $header-height;
    }
}

//
// Sidebar
//
.sidebar-hidden {
    .sidebar {
        margin-left: - $sidebar-width;
    }
}

//
// Sidebar fixed
//
.sidebar-fixed {
    .sidebar {
        position: fixed;
        z-index: $zindex-sticky - 1;
        width: $sidebar-width;
        height: calc(100vh - #{$header-height});
    }
    .main,
    .app-footer {
        margin-left: $sidebar-width;
    }
    &.sidebar-hidden {
        .main,
        .app-footer {
            margin-left: 0;
        }
    }
}

//
// Ui view
//
.ui-view {
    padding: 0 45px;
    //mix-blend-mode: overlay;
}


@include media-breakpoint-down(lg) {
    .app-body {
        .sidebar {
           flex: 0 0 250px;
        }
    }

    .sidebar-fixed .sidebar{
        width: 250px;
    }

    .sidebar-fixed .main, .sidebar-fixed .app-footer {
        margin-left: 250px;
    }
}


//
// > 1199
//
@include media-breakpoint-down(sm) {
    .sidebar-fixed {
        .main,
        .app-footer {
            margin-left: 0px;
        }
    }

    .sidebar-fixed .sidebar, .sidebar {
        position: fixed;
        z-index: 1019;
        width: 100%;
        max-width: 100%;
        height: calc(100vh - 70px);
    }

    .sidebar{
        transform: translateX(-100%);
    }

    .sidebar-show{
        .sidebar{
            transform: translateX(0%);
        }
    }
    
    .ui-view{
        padding: 0 25px;
    }

    .sidebar-fixed .main, .sidebar-fixed .app-footer {
        margin-left: 0px;
    }
    .header-fixed .app-header, .app-header{
        height: 70px;
        flex: 0 0 70px;
        position: fixed;
        z-index: 1020;
        width: 100%;
    }
    .header-fixed .app-body, .app-body{
        margin-top: 70px;
    }

    .ui-view > h2:first-of-type{
        margin-bottom: 1rem!important;
    }
}

//
// < 1199
//
@include media-breakpoint-up(xl) {
    .sidebar-minimized {
        .sidebar {
            flex: 0 0 $sidebar-minimized-width;
        }
        &.sidebar-hidden {
            .sidebar {
                margin-left: - $sidebar-minimized-width;
            }
        }
        &.sidebar-fixed {
            .main,
            .app-footer {
                margin-left: $sidebar-minimized-width;
            }
            .sidebar {
                width: $sidebar-minimized-width;
            }
            &.sidebar-hidden {
                .main,
                .app-footer {
                    margin-left: 0;
                }
            }
        }
    }
}