
.text-primary{ color: $bg-primary!important; }
.text-secoundary{ color: $bg-secoundary!important; }
.text-gray{ color: $color-gray!important; }
.text-white{ color: $color-white!important; }
.color-gray { color: $color-gray };
.color-white { color: $color-white; }
.color-hover { color: $color-hover; }

.bg-primary{ background-color: $bg-primary!important; }
.bg-secoundary{ background-color: $bg-secoundary!important; }
.bg-gray{ background-color: $color-gray!important; }
.bg-white{ background-color: $color-white!important; }
.bg-hover { background-color: $color-hover; }




.font-weight-light { font-weight: 300; font-style: normal;}
.font-weight-normal { font-weight: normal; font-style: normal; }
.font-weight-semi { font-weight: 600; font-style: normal;}
.font-weight-bold{ font-weight: bold; font-style: normal; }
.text-uppercase { text-transform: uppercase; }

.display-1, .display-2, .display-3, .display-4, .display-5, .display-6{
    font-weight: inherit;
    line-height: inherit;
}
.display-1 { font-size: $display-1 }
.display-2 { font-size: $display-2 }
.display-3 { font-size: $display-3 }
.display-4 { font-size: $display-4 }
.display-5 { font-size: $display-5 }
.display-6 { font-size: $display-6 }


.no-padding { padding: 0!important; }

