
/*  widget base */
.ui-widget {
    box-shadow: $widget-shadow;
    margin-bottom: 45px;
}

.ui-widget-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $widget-title-padding;
    background-color: $bg-secoundary;
    color: $color-white;

    .btn{
        padding-left: 20px;
        padding-right: 20px;
    }
}

.ui-widget-head {
    padding: $widget-head-padding;
    background-color: #0f1313;
}

.ui-widget-body {
    padding: 25px 20px;
    background-color: $bg-drop;
}

.ui-widget-footer {
    padding: 16px 20px;
    border-top: 1px solid $color-border;
}

.ui-widget-body .ui-widget-footer {
    margin-left: -20px;
    margin-right: -20px;
}


/*  widget note */
.widget-note{
    position: relative;
    padding-right: 45px;

    [class^="icon"]{
        position: absolute;
        right: 10px;
        top: 20px;
    }

    p + p{
        margin-top: 10px;
    }
}

.app-settings{
    position: fixed;
    top: 110px;
    right: 0;
    transform: translateX(220px);
    transition: -webkit-transform 225ms ease-in-out;
    z-index: 999;
    box-shadow: $widget-shadow;

    .theme-option{
        padding: 16px;
        border-bottom: 1px solid  rgba( 255, 255, 255, 0.05);

        .option-title {
            font-size: 14px;
            font-weight: 500;
            padding: 0 8px 12px;
        }
    }
    .theme-option:last-of-type{
        border-bottom: 0px;
    }
}

.app-settings-toggle{
    position: absolute;
    top: 0;
    left: -48px;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-white;
    font-size: 24px;
    background-color: #293030;
    cursor: pointer;
    z-index: 99;
}

.app-settings-content{
    width: 220px;
    min-width: 220px;
    max-width: 220px;
    min-height: 400px;
    background: #202626;
    z-index: 12;
}

.app-settings.open{
    transform: translateX(0px);
}


@include media-breakpoint-down(sm) {
    .app-settings{
        display: none;
    }
    .mCustomScrollbar:not(.sidebar){
        height: auto!important;
        touch-action: initial;
    }
    .ui-widget-body{
        overflow-x: scroll
    }
}

