//
// Theme dark
//
.theme-dark {
   
}

//
// Theme white blue
//
.theme-white-blue {
    background-color: #edeff5; 
    
    //
    // Theme Colors
    //
    .theme-color {
        color: #01c0a4;
    }
    .theme-bg {
        background-color: #111515;
    }
    .theme-background {
        background-color: #111515;
    }
    .theme-text-color {
        color: #f4efef;
    }
    h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6,.h6 {
        color: #2f323a;
    } 

    a:focus, a:hover, a:active{
        color: #5093e1;
    }

    .highlight{
        color: #5093e1;
    }

    .bg-primary {
        background-color: #edeff5 !important;
    }
    
    //
    // Widget
    //
    .ui-widget-title {
        background-color: #2f323a;
        color: #d3d1d1;
    }
    .ui-widget-body {
        background-color: #fff;
    }
    .ui-widget-head {
        background-color: #edeff5;
    } 

    .ui-widget-footer {
       border-top: 1px solid #e5e5e5;
    }
    
    //
    // Modal
    //
    .modal-header {
        background-color: #2f323a;
        color: #dbd7d7;
        
        h2, h3, h4, h5, h6 {
            color: #dbd7d7;
        }
    }
    .modal-body {
        background-color: #fff;
    }
    .modal-footer {
        background-color: #fff;
    }

    //
    // Scrollbar
    //
    .mCSB_scrollTools .mCSB_draggerContainer {
        background-color: #d3d1d1;
    }

    .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar{
        background-color: #5093e1;
    }

    //
    // Table
    //
    .table thead th, .table th{
        background-color: #edeff5;
        color: #625f5f;
    }

    .table tbody td{
        background-color: #fff;
        border-bottom: 1px solid #d5d7dc;
        color: #625f5f;
    }

    .indent-table td, .indent-table td:first-child{
        background-color: #edeff5!important;
    }
    .thead-border thead{
        border-top: 1px solid #bebfc4;
    }

    .table-hover tbody tr:hover td{
        background-color: #5093e1;
        color: #fff!important;
    }

    //
    // Forms
    //
    .custom-control-switch .custom-control-description{
        background-color: #edeff5;
        color: #625f5f;
    }

    .custom-control-switch .custom-control-input:checked ~ .custom-control-description.switch-success{
        color: #fff;
        background-color: #50c14e;
    }

    .custom-control-switch .custom-control-input:checked ~ .custom-control-description.switch-danger{
        color: #fff;
        background-color: #f44545;
    }

    .form-control.parsley-error{
        border-color: #f44545;
    }

    ul.parsley-errors-list{
        color: #f44545;
    }

    .form-control{
        border: 1px solid #d5d6d8;
        color: #625f5f;
    }

    .form-control:focus{
        border-color: #5093e1;
    }

    .custom-control-input:checked ~ .custom-control-indicator{
        background-color: #5093e1;
    }

    label{
        color: #625f5f;
    }

    .form-control.success {
        border-color: #5093e1;
    }

    .form-control:disabled{
        background-color: #e9ecef;
    }

    //
    // Btn
    //
    .btn-toggle.open{
        color: #625f5f;
    }

    .btn:hover{
        color: #5093e1;
    }

    .btn-group.show{
        background-color: #2f323a;
    }

    .dropdown-menu{
        background-color: #fff;
        box-shadow: none;
    }
    .dropdown-item{
        border-bottom: 1px solid #f5f5f5;
    }

    .dropdown-item:focus, .dropdown-item:hover{
        background-color: #2f323a;
        color: #fff;
    }

    .btn-group-separator .btn{
        border-right: 1px solid #e5e5e5;
    }

    .btn-primary{
        background-color: #5093e1;
        border-color: #5093e1;
    }

    .btn-primary:hover{
        border-color: #5093e1;
        color: #fff;
    }

    .btn-secoundary{
        background-color: #5093e1;
        color: #fff;
    }

    //
    // App Header
    //
    .app-header{
        background-color: #fff;
    }

    .app-header .btn{
        color: #625f5f;
    }

    .header-brand .text-white{
        color: #080a0a!important;
    }

    .header-alarm .badge-icon .badge{
        background-color: #5093e1;
        color: #fff;
    }

    .breadcrumb-item.active{
        color: #5093e1;
    }

    .header-alarm .btn [class^="icon"] {
        color: #5093e1;
    }

    .app-header{
        box-shadow: 0 2px 6px -2px rgba(0,0,0,.25);
    }

    //
    // Pagimation
    //

    .page-link-nav:focus, .page-link-nav:hover{
        border-color: #5093e1;
    }
        

    //
    // Sidebar
    //

    .sidebar{
        background-color: #2f323a;
    }

    .sidebar-nav .nav-link-dropdown{
        background-color: #5093e1;
    }

    .sidebar-nav .nav-link:hover, .sidebar-nav .nav-link:hover i{
        color: #5093e1;
    }
    
    .sidebar-nav .nav-dropdown-items .nav-dropdown-items{
        background-color: #fff;
    }

    .sidebar-nav .nav-dropdown-items .nav-dropdown-items .nav-link{
        color: #625f5f;
    }

    .sidebar-nav .nav-dropdown-items .nav-dropdown-items .nav-link:hover:after {
        background-color: #5093e1;
    }

    .sidebar-nav .nav-dropdown-items .nav-item.open > .nav-link, .sidebar-nav .nav-dropdown-items .nav-item.open > .nav-link i{
        color: #5093e1;
    }

    .sidebar-nav .nav > .nav-item {
        border-bottom: 1px solid #4079be;
    }

    .sidebar-nav .nav-link-dropdown{
        color: #fff!important;
    }

    //
    // Tabs
    //

    .nav-tabs .nav-link{
        border-color: #d5d7dc;
    }

    .nav-tabs .nav-link:hover{
        color: #000;
    }

    .nav-tabs .nav-link.active{
        color: #5093e1;
    }

    //
    // Preloader
    //

    .app-preloader{
        background-color: #edeff5;
    }

    .loader {
       border-top: 8px solid rgba(0, 0, 0, 0.15);
        border-right: 8px solid rgba(0, 0, 0, 0.15);
        border-bottom: 8px solid rgba(0, 0, 0, 0.15);
        border-left: 8px solid #5093e1;
    }
}


//
// Theme white orange
//
.theme-white-orange {
    background-color: #f6f0ec; 
    
    //
    // Theme Colors
    //
    .theme-color {
        color: #01c0a4;
    }
    .theme-bg {
        background-color: #111515;
    }
    .theme-background {
        background-color: #111515;
    }
    .theme-text-color {
        color: #f4efef;
    }
    h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6,.h6 {
        color: #3b3733;
    } 

    a:focus, a:hover, a:active{
        color: #e65535;
    }

    .highlight{
        color: #e65535;
    }

    .bg-primary {
        background-color: #edeff5 !important;
    }
    
    //
    // Widget
    //
    .ui-widget-title {
        background-color: #ffffff;
        color: #3b3733;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
        position: relative;
        z-index: 22;
    }
    .ui-widget-body {
        background-color: #fff;
    }
    .ui-widget-head {
        background-color: #3b3733;
    } 

    .ui-widget-footer {
       border-top: 1px solid #e5e5e5;
    }
    
    //
    // Modal
    //
    .modal-header {
        background-color: #fff;
        color: #3b3733;;
        
        h2, h3, h4, h5, h6 {
            color: #3b3733;
        }
    }
    .modal-body {
        background-color: #fff;
    }
    .modal-footer {
        background-color: #fff;
    }

    //
    // Scrollbar
    //
    .mCSB_scrollTools .mCSB_draggerContainer {
        background-color: #d3d1d1;
    }

    .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar{
        background-color: #e65535;
    }

    //
    // Table
    //
    .table thead th, .table th{
        background-color: #3b3733;
        color: #e4e3e3;
    }

    .table tbody td{
        background-color: #fff;
        border-bottom: 1px solid #d5d7dc;
        color: #3b3733;
    }

    .indent-table td, .indent-table td:first-child{
        background-color: #3b3733!important;
    }
    .thead-border thead{
        border-top: 1px solid #bebfc4;
    }

    .table-hover tbody tr:hover td{
        background-color: #3b3733;
        color: #fff!important;
    }

    //
    // Forms
    //
    .custom-control-switch .custom-control-description{
        background-color: #edeff5;
        color: #625f5f;
    }

    .custom-control-switch .custom-control-input:checked ~ .custom-control-description.switch-success{
        color: #fff;
        background-color: #50c14e;
    }

    .custom-control-switch .custom-control-input:checked ~ .custom-control-description.switch-danger{
        color: #fff;
        background-color: #e65535;
    }

    .form-control.parsley-error{
        border-color: #e65535;
    }

    ul.parsley-errors-list{
        color: #e65535;
    }

    .form-control{
        border: 1px solid #d5d6d8;
        color: #625f5f;
    }

    .form-control:focus{
        border-color: #e65535;
    }

    .custom-control-input:checked ~ .custom-control-indicator{
        background-color: #e65535;
    }

    label{
        color: #625f5f;
    }

    .form-control.success {
        border-color: green;
    }

    .form-control.parsley-error {
        border-color: red;
    }

    .form-control:disabled{
        background-color: #e9ecef;
    }

    //
    // Btn
    //
    .btn-toggle.open{
        color: #625f5f;
    }

    .btn:hover{
        color: #5093e1;
    }

    .btn-group.show{
        background-color: #e65535;
    }

    .dropdown-menu{
        background-color: #fff;
        box-shadow: none;
    }
    .dropdown-item{
        border-bottom: 1px solid #f5f5f5;
    }

    .dropdown-item:focus, .dropdown-item:hover{
        background-color: #e65535;
        color: #fff;
    }

    .btn-group-separator .btn{
        border-right: 1px solid #e5e5e5;
    }

    .btn-primary{
        background-color: #e65535;
        border-color: #e65535;
    }

    .btn-primary:hover{
        border-color: #e65535;
        color: #fff;
    }

    .btn-secoundary{
        background-color: #e65535;
        color: #fff;
    }

    //
    // App Header
    //
    .app-header{
        background-color: #3b3733;
    }

    .app-header .btn{
        color: #e4e3e3;
    }

    .header-brand .text-white{
        color: #e4e3e3!important;
    }

    .header-alarm .badge-icon .badge{
        background-color: #e65535;
        color: #e4e3e3;
    }

    .breadcrumb-item.active{
        color: #e65535;
    }

    .header-alarm .btn [class^="icon"] {
        color: #e4e3e3;
    }

    .app-header{
        box-shadow: 0 2px 6px -2px rgba(0,0,0,.25);
    }

    //
    // Pagimation
    //

    .page-link-nav:focus, .page-link-nav:hover{
        border-color: #e65535;
    }
        

    //
    // Sidebar
    //

    .sidebar{
        background-color: #2f323a;
    }

    .sidebar-nav .nav-link-dropdown{
        background-color: #e65535;
    }

    .sidebar-nav .nav-link:hover, .sidebar-nav .nav-link:hover i{
        color: #e65535;
    }

    .sidebar-nav .nav-dropdown-items .nav-dropdown-items{
        background-color: #fff;
    }

    .sidebar-nav .nav-dropdown-items .nav-dropdown-items .nav-link{
        color: #625f5f;
    }

    .sidebar-nav .nav-dropdown-items .nav-dropdown-items .nav-link:hover:after {
        background-color: #e65535;
    }

    .sidebar-nav .nav-dropdown-items .nav-item.open > .nav-link, .sidebar-nav .nav-dropdown-items .nav-item.open > .nav-link i{
        color: #e65535;
    }

    .sidebar-nav .nav > .nav-item {
        border-bottom: 1px solid #cb4e33;
    }

    .sidebar-nav .nav-link-dropdown{
        color: #fff!important;
    }

    //
    // Tabs
    //

    .nav-tabs .nav-link{
        border-color: #625f5c;
        color: #e4e3e3;
    }

    .nav-tabs .nav-link:hover{
        color: #fff;
    }

    .nav-tabs .nav-link.active{
        color: #e65535;
    }

    //
    // Preloader
    //

    .app-preloader{
        background-color: #f6f0ec;
    }

    .loader {
       border-top: 8px solid rgba(0, 0, 0, 0.15);
        border-right: 8px solid rgba(0, 0, 0, 0.15);
        border-bottom: 8px solid rgba(0, 0, 0, 0.15);
        border-left: 8px solid #e65535;
    }
}
