

.modal-backdrop{
    background-color: $bg-primary;
}

.modal-backdrop.show{
    opacity: 0.9;
}

.modal-dialog {
    min-height: calc(100vh - 60px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: auto;
    @media(max-width: 768px) {
      min-height: calc(100vh - 20px);
    }
}

.modal-body{
    padding: 20px;
    padding-top: 30px;
}

.modal-content{
    background-color: $bg-drop;
    box-shadow: -1px -1px 14px rgba(0, 0, 0, 0.25), 1px 1px 14px rgba(0, 0, 0, 0.25);
    border-radius: 0px;
}

.modal-header{
    background-color: $bg-secoundary;
    border-bottom: 0;
    border-radius: 0px;
    padding-left: 20px;
}

.modal-header .close{
    color: $color-white;
    opacity: 0.8;
    font-weight: 300;
    font-size: 16px;
    margin-top: -12px;
}

.modal-footer{
    border-top: 0;
}