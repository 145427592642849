//
// General
//
.btn:focus,
.btn.focus {
    outline: 0;
    box-shadow: none;
}

button {
    cursor: pointer;
}

.btn.disabled,
.btn:disabled {
    background-color: $color-border;
    border-color: $color-border;
}

.btn {
    background-color: transparent;
    color: $color-gray;
    @extend .display-2;
    display: inline-flex;
    align-items: center;
    border-radius: 0px;

    [class^="icon"] {
        margin-right: 10px;
        font-size: 120%;
    }
}

.btn:hover {
    color: $color-white;
}

.table .btn {
    padding-top: 0;
    padding-bottom: 0;
}

.btn-group-separator{
    display: flex;
    flex-wrap: wrap;
    .btn{
        border-right: 1px solid $color-border;
    }

    .btn:last-of-type{
        border-right: 0px!important;
    }
}


//
// Btn variations
//
.btn-primary {
    background-color: $color-hover;
    border-color: $color-hover;
    color: $color-white;
}

.btn-primary:hover {
    color: $color-white;
    background-color: $color-hover;
    border-color: $color-hover;
}

.btn-secoundary{
   background-color: $bg-primary;
}

.btn-group.show {
    background-color: $bg-drop;
    box-shadow: 0px 2px rgba(0, 0, 0, 0.4);
}

//
// Dropdown Menu
//
.dropdown-menu {
    background-color: $bg-drop;
    border-radius: 0px;
    padding: 0;
    min-width: 100%;
    margin: 0;
    border: 0px;
    box-shadow: inset -1px 1px rgba(0, 0, 0, 0.4);
}

.dropdown-item {
    color: $color-gray;
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
    font-size: 14px;
    padding: 14px 25px;
    transition: $transition-base;
}

.dropdown-menu .dropdown-item:last-of-type {
    border-bottom: 0px;
}

.dropdown-item:focus,
.dropdown-item:hover {
    color: $color-hover;
    text-decoration: none;
    background-color: $bg-secoundary;
}

.dropdown-divider {
    border-top: 1px solid #303737;
}

.dropdown-menu-fixed {
    width: 260px;
    .dropdown-item {
        white-space: normal;
    }
}


//
// Link
//
.link-icon {
    display: flex;
    align-items: center;
    @extend .display-2;
    font-weight: 600;
    [class^="icon"] {
        font-size: 8px;
        margin-right: 6px;
        line-height: 1.2;
    }
}

//
// Btn toggle
//
.btn-toggle, .btn-toggle[aria-expanded=false] {
    position: relative;
    &:before {
        content: "\e911";
        margin-right: 8px;
        font-size: 80%;
        font-family: 'icomoon' !important;
    }
}

.btn-toggle.open:before, .btn-toggle[aria-expanded=true]:before {
    content: "\e90d";
}

