.app-header {
    background-color: $bg-secoundary;
}

.header-brand {
    display: flex;
    align-items: center;
    padding-left: $nav-link-padding-x;

    a{
        display: flex;
        align-items: center;
    }
    i {
        @extend .text-white;
        font-size: 26px;
        margin-right: $nav-link-padding-x;
    }
}

.app-header-content {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 100%;
}

.header-items {
    height: 100%;
    padding-right: 20px;
}

.header-avatar {
    display: flex;
    align-items: center;
    .media-avatar {
        width: 58px;
        height: 52px;
        border: 2px solid #fff;
        background-color: #dedede;
        background-size: cover;
        background-position: 50%;
    }
    .body-avatar {
        p {
            margin: 0;
        }
    }
}

.app-header .btn-group,
.app-header .btn {
    height: 100%;
}

.app-header .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
    color: $color-white;
}

.header-alarm{
    .badge-icon{
        font-size: 20px;
        color: $color-hover;
    }

    .btn [class^="icon"]{
        margin: 0;
    }
    .badge-icon {
        position: relative;
        .badge {
            padding: 4px 4px;
            line-height: 1;
            position: absolute;
            right: 0;
            top: 50%;
            margin-top: -2px;
            transform: translate(75%, -50%);
        }
    }
}


@include media-breakpoint-down(sm) {
    .header-brand a span, .header-avatar .media-avatar{
        display: none;
    }
    .app-header .header-alarm .btn{
        padding-left: 0;
    }
    .header-brand i{
        margin-right: 10px;
    }
}