/* This stylesheet generated by Transfonter (https://transfonter.org) on November 2, 2017 11:19 AM */

@font-face {
    font-family: 'Proxima Nova';
    src: url(#{$path-font-proxima}ProximaNova-Regular.eot);
    src: url(#{$path-font-proxima}ProximaNova-Regular.eot?#iefix) format('embedded-opentype'),
        url(#{$path-font-proxima}ProximaNova-Regular.woff) format('woff'),
        url(#{$path-font-proxima}ProximaNova-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url(#{$path-font-proxima}ProximaNova-Bold.eot);
    src: url(#{$path-font-proxima}ProximaNova-Bold.eot?#iefix) format('embedded-opentype'),
        url(#{$path-font-proxima}ProximaNova-Bold.woff) format('woff'),
        url(#{$path-font-proxima}ProximaNova-Bold.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url(#{$path-font-proxima}ProximaNova-Light.eot);
    src: url(#{$path-font-proxima}ProximaNova-Light.eot?#iefix) format('embedded-opentype'),
        url(#{$path-font-proxima}ProximaNova-Light.woff) format('woff'),
        url(#{$path-font-proxima}ProximaNova-Light.ttf) format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url(#{$path-font-proxima}ProximaNova-Semibold.eot);
    src: url(#{$path-font-proxima}ProximaNova-Semibold.eot?#iefix) format('embedded-opentype'),
        url(#{$path-font-proxima}ProximaNova-Semibold.woff) format('woff'),
        url(#{$path-font-proxima}ProximaNova-Semibold.ttf) format('truetype');
    font-weight: 600;
    font-style: normal;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6{
    font-family: 'Proxima Nova';
    font-weight: normal;
    font-style: normal;
    color: $color-white;
}

p{
    @extend .display-2;
    color: $color-gray;
    font-weight: 600;
}