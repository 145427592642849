.table thead th,
.table th,
.table td {
    @extend .display-1;
    @extend .text-gray;
    font-weight: 600;
    background-color: #0f1313;
    border: 0px;
    padding: 20px 20px
}

.table tbody td {
    @extend .display-2;
    @extend .text-gray;
    font-weight: 600;
    background-color: $bg-drop;
    border-bottom: 1px solid #222828;
    padding: 20px 20px
}

.table tbody+tbody,
.table tbody:last-child tr:last-child td {
    border: 0px;
}

.table {
    
    label,
    p {
        margin-bottom: 0;
    }
}

.table-striped tbody tr:nth-of-type(even) td {
    background-color: #0f1313;
}

.table-bordered td,
.table-bordered th {
    border: 1px solid #222828!important;
}

.table-hover tbody tr:hover td {
    background-color: #0f1313;
}

.indent-table td, .indent-table td:first-child{
    background-color: #0f1313!important;
    padding-left: 40px;
}




.table-middle{
    td{
        vertical-align: middle;
    }
}
.thead-border{
    thead{
        border-top: 1px solid $color-border;
    }
}

.scroll-table {
    overflow-x: auto;
}

.scroll-table table td, .scroll-table table td {
    min-width: 170px;
}

@include media-breakpoint-down(xl) {
    .table thead th, .table th, .table td{ 
        padding: 20px 15px;
    }
}


.treegrid-indent {width:16px; height: 16px; display: inline-block; position: relative;}

.treegrid-expander {
    display: none;
}

// .treegrid-expander-expanded{background-image: url(../img/collapse.png); }
// .treegrid-expander-collapsed{background-image: url(../img/expand.png);}

[class*="treegrid-parent"] td {
    background-color: #0f1313!important;
}



