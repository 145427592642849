$global-font-size: 100% !default;
$zindex-sticky: 1020;
$transition-base: all .3s ease-in-out;
// Global Path
$path-icomoon: '../fonts/icomoon/';
$path-font: '../fonts/';
$path-img: '../img/';
$path-font-proxima: '../fonts/proxima-nova/';
// App colors
$bg-primary: #111515 !default;
$bg-secoundary: #080a0a !default;
$bg-drop: #161c1c !default;
$color-gray: #625f5f !default;
$color-hover: #01c0a4 !default;
$color-white: #f4efef !default;
$color-border: #2e3333 !default;
$color-alert: #96042a !default;
// Body
$body-background: $bg-primary !default;
$body-padding: 0px !default;
$body-margin: 0px !default;
// Header
$header-height: 95px !default;
$header-background: $bg-secoundary !default;
// Display sizes
$display-1: 13px;
$display-2: 14px;
$display-3: 15px;
$display-4: 16px;
$display-5: 18px;
$display-6: 20px;
// Sidebar
$sidebar-width: 350px !default;
$sidebar-minimized-width: 80px !default;
$sidebar-minimized-height: $sidebar-minimized-width !default;
$sidebar-color: #fff !default;
$sidebar-background: $bg-secoundary !default;
$sidebar-padding: 0 !default;
$sidebar-left-space: 85px !default;
$sidebar-shadow: 0px 2px 2px rgba(0, 0, 0, 0.8);
$nav-link-padding-y: 20px;
$nav-link-padding-x: 25px;
// Widget
$widget-title-padding: 20px;
$widget-head-padding: 20px;
$widget-body-padding: 0px;
$widget-shadow: -1px -1px 9px rgba(0, 0, 0, 0.15),
1px 1px 9px rgba(0, 0, 0, 0.15);
// Footer
$footer-height: 50px !default;