.app-body .sidebar {
  -webkit-flex: 0 0 250px;
  -ms-flex: 0 0 250px;
  flex: 0 0 250px;
  width: 250px;
}

.sidebar-fixed .main, .sidebar-fixed .app-footer {
  margin-left: 250px;
}

.sidebar-nav .nav-link {
    padding-left: 25px;
    text-align: center;
    &:not(.nav-link-dropdown) {
        padding-left: 55px;
    }
}

.sidebar-nav .nav-dropdown-items .nav-dropdown-items .nav-link {
  padding-left: 30px;
}

.sidebar-nav .nav-dropdown-items .nav-dropdown-items:after, .sidebar-nav .nav-dropdown-items .nav-dropdown-items .nav-link:after {
  display: none;
}

.app-projects {
  position: fixed;
  top: 160px;
  right: 0;
  -webkit-transform: translateX(400px);
  -ms-transform: translateX(400px);
  transform: translateX(400px);
  transition: -webkit-transform 225ms ease-in-out;
  z-index: 999;
  background-color: #fff;
  box-shadow: -1px -1px 9px rgba(0, 0, 0, 0.15), 1px 1px 9px rgba(0, 0, 0, 0.15);
}
.app-projects.open {
  -webkit-transform: translateX(0px);
  -ms-transform: translateX(0px);
  transform: translateX(0px);
}
.app-projects-content {
  width: 400px;
  min-width: 400px;
  max-width: 400px;
  background: #fff;
  z-index: 12;
  padding: 10px;
}
.app-project {
  border-bottom: 1px solid #ffffff0d;
  padding-bottom: 16px;
  padding-top: 16px;
}
.app-project h6 {
  margin-bottom: 0;

}
.app-project .app-project-options {

}
.app-project .app-project-start {

}

.has-thumb {
  position: relative;
  z-index: 0;
  display: inline-block;
}

.has-thumb:hover {
  z-index: 50;
}

.has-thumb span {
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
  display: none;
}

.has-thumb:hover span {
  display: block;
}

.has-thumb span img {
  max-width: 500px;
}
