//
// General Style
//

.form-control {
    display: block;
    width: 100%;
    padding: rem-calc(10 16);
    @extend .display-2;
    height: auto;
    line-height: 1.5;
    color: $color-white;
    background-color: transparent;
    border: 1px solid $color-border;
    border-radius: 0px;
}

.form-control:focus {
    color: $color-white;
    background-color: transparent;
    border-color: $color-hover;
    box-shadow: none;
    outline: none;
}

.form-control:disabled,
.form-control[readonly] {
    background-color: $color-border;
}

.was-validated .form-control:invalid,
.form-control.is-invalid,
.was-validated .custom-select:invalid,
.custom-select.is-invalid {
    border-color: $color-alert;
}

ul.parsley-errors-list {
    list-style: none;
    padding: rem-calc(10 16);
    color: $color-alert;
    font-size: 14px;
}

.form-control.parsley-error {
    border-color: $color-alert;
}

.invalid-feedback {
    color: $color-alert;
}

.form-control-lg {
    @extend .display-4;
}

.form-control-sm {
    @extend .display-1;
}

.form-control-plaintext {
    color: $color-gray;
}

.col-form-label {
    padding-top: 10px;
    padding-bottom: 10px;
}

legend {
    color: $color-gray;
}

.form-group {
    margin-bottom: 20px;
}

label {
    @extend .display-2;
    font-weight: 600;
    font-style: normal;
    color: $color-gray;
}

.label-fixed-width{
    min-width: 160px;
}

.form-text{
    padding-left: 1rem;
    color: $color-gray;
    font-size: 13px;
}

.form-control.success {
    border-color: $color-hover;
}

.form-control[readonly]{
    background-color: #e9ecef;
}


//
// Select
//

select.form-control:not([size]):not([multiple]) {
    height: 43px;
}


select {
    border-radius: 0px;
}

.custom-select {
    height: calc(2.25rem + 2px);
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    line-height: 1.5;
    color: $color-gray;
    max-width: 100%;
    width: auto;
    @extend .display-2;
    vertical-align: middle;
    background-size: 8px 10px;
    background-color: transparent;
    border: 1px solid $color-gray;
    border-radius: 0;
    position: relative;
}

.custom-file {
    position: relative;
    display: inline-block;
    max-width: 100%;
    width: auto;
    height: calc(2.25rem + 2px);
    margin-bottom: 0;
}

.custom-file-input {
    min-width: 14rem;
    max-width: 100%;
    height: calc(2.25rem + 2px);
    margin: 0;
    opacity: 0;
}

.custom-file-control:lang(en)::before {
    content: "Browse";
}

.custom-file-control:lang(en):empty::after {
    content: "Choose file...";
}

.custom-file-control {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 5;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}

.custom-file-control::before {
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    z-index: 6;
    display: block;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: 0 0.25rem 0.25rem 0;
}

//
// Custom Control
//
.custom-control {
    position: relative;
    display: inline-flex;
    min-height: 1.5rem;
    padding-left: 1.5rem;
    margin-right: 1rem;
    .custom-control-input {
        position: absolute;
        left: -9999px;
        top: -9999px;
    }
}

.custom-radio .custom-control-indicator {
    border-radius: 50%;
}

.custom-control-indicator {
    position: absolute;
    top: 0.25rem;
    left: 0;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: #ddd;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-indicator {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-indicator {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns= 'http://www.w3.org/2000/svg' viewBox= '0 0 8 8' %3E%3Cpath fill= '%23fff' d= 'M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z' /%3E%3C/svg%3E");
}


.form-check {
    position: relative;
    display: block;
    margin-bottom: 0.5rem;
}

.form-check-inline {
    display: inline-block;
    margin-right: 0.75rem;
}

.custom-control-input:checked~.custom-control-indicator {
    background-color: $color-hover;
}
.custom-checkbox .custom-control-indicator{
    border-radius: 0px;
}

.group-checkbox{
    padding-left: 20px;
    margin-bottom: 20px;
}

.custom-control.custom-checkbox{
    padding-left: 45px;
    margin-right: 40px;
    min-height: 25px;
}

.custom-checkbox .custom-control-indicator{
    width: 25px;
    height: 25px;
}

.custom-checkbox .custom-control-description{
    padding-top: 5px;
}

.custom-input {
    position: relative;
    .form-control {
        padding-top: 22px;
    }
    label {
        padding: rem-calc(5 16);
        left: 0;
        right: 0;
        top: 0px;
        position: absolute;
        margin: 0;
        pointer-events: none;
    }
}

.custom-input:focus {
    border-color: $color-hover;
}

.form-inline {
    .custom-select {
        margin-left: 10px;
        margin-right: 10px;
    }
}

.label-custom-file input[type="file"] {
    position: absolute;
    z-index: -1;
    opacity: 0;
}


//
// File upload
//
.fileuplod-holder{
    margin: 1rem 0;
    display: flex;
    align-items: center;
    overflow: hidden;
    position: relative;

    label{
        margin: 0;
        position: relative;
    }

    .image-preview{
        position: relative;
        height: 60px;
        width: 60px;
        background-color: transparent;
        border:1px solid $color-border;
        margin-right: 2rem;
    }
    .image-preview-bg{
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-size: cover;
        background-position: 50%;
    }

    .btn-group-separator{
        display: flex;
        align-items: center;
    }
}

.fileuplod-holder.has-value:hover{
    .image-preview:after{
        background-color: rgba(8, 10 , 10, 0.45);
    }
}

.fileuplod-holder-block{
    display: block;
    label{
        display: block;
        width: 100%;
        padding-bottom: 26.5%;
    }

    .image-preview{
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        &:after{
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: rgba(8, 10 , 10, 0.2);
            transition: all 0.3s;
        }
    }
    .btn{
        color: #fff;

        &:hover{
            color: $color-hover;
        }
    }

    .fileuplod-holder-block-btn-group{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        text-align: center;
    }
}

.fileuplod-holder-block{
    .fileuplod-holder-block-btn-group{
        display: none;
    }
}

.fileuplod-holder-block .fileuplod-holder-info{
    position: absolute;
    pointer-events: none;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

}

.fileuplod-holder-block.has-value{
    .fileuplod-holder-block-btn-group{
        display: block;
    }
    .fileuplod-holder-info{
        display: none;
    }
}

.fh-lg{
    margin-top: 0;
}

.fh-lg label{
    padding-bottom: 66%;
}


//
// Switch
//
.custom-control-switch{
    padding: 0;
    margin: 0;
    input {
        position: absolute;
        z-index: -1;
        opacity: 0;
    }
    .custom-control-indicator{
        display: none;
    }
    .custom-control-description{
        padding: 10px 12px;
        background-color: #2b3737;
        color: #fff;
        min-width: 50px;
        text-align: center;
        display: inline-block;
        padding: 3px 6px;
        font-weight: 600;
        border-radius: 0;
    }

    .custom-control-input:checked ~ .custom-control-description.switch-success{
        background-color: #3a840d;
    }
    .custom-control-input:checked ~ .custom-control-description.switch-danger{
        background-color: #96042a;
    }
}

@include media-breakpoint-down(sm) {
    .group-checkbox{
        .custom-control{
            margin-right: 0;
            display: block;
            width: 100%;
        }
    }
    .custom-control.custom-checkbox{
        padding-top: 8px;
    }
    .label-fixed-width{
        width: 100%;
    }
    .fileuplod-holder-block label{
        padding-bottom: 60%;
    }
}




