.nav-tabs {
    border-bottom: 0;
}

.nav-tabs .nav-link {
    @extend .display-2;
    color: $color-gray;
    border: 0px;
    border-radius: 0px;
    border-right: 1px solid $color-gray;
    padding: rem-calc(4 24);
}

.nav-tabs .nav-link:hover {
    border-color: $color-gray;
    color: $color-white;
}

.nav-tabs .nav-link.active {
    color: $color-hover;
    background-color: transparent;
    border-color: $color-gray;
}

.ui-widget-head .nav-tabs .nav-link:first-of-type {
    padding-left: 0;
}

.ui-widget-head .nav-tabs .nav-link:last-of-type {
    border-right: 0px;
}

@include media-breakpoint-down(sm) {
    .nav-tabs{
        flex-wrap: nowrap;
        overflow-x: scroll;
        padding: 1.25rem 0;
    }
    .nav-tabs .nav-link{
        white-space: nowrap;
    }
}
