.badge {
    display: inline-block;
    padding: 3px 6px;
    @extend .display-2;
    font-weight: 600;
    border-radius: 0;
}

.table .badge {
    min-width: 60px;
}