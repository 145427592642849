.mfp-bg {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1042;
    overflow: hidden;
    position: fixed;
    background: #0b0b0b;
    opacity: .8
}

.mfp-wrap {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1043;
    position: fixed;
    outline: 0!important;
    -webkit-backface-visibility: hidden
}

.mfp-container {
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    padding: 0 8px;
    box-sizing: border-box
}

.mfp-container:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle
}

.mfp-align-top .mfp-container:before {
    display: none
}

.mfp-content {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin: 0 auto;
    text-align: left;
    z-index: 1045
}

.mfp-ajax-holder .mfp-content,.mfp-inline-holder .mfp-content {
    width: 100%;
    cursor: auto
}

.mfp-ajax-cur {
    cursor: progress
}

.mfp-zoom-out-cur,.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
    cursor: -moz-zoom-out;
    cursor: -webkit-zoom-out;
    cursor: zoom-out
}

.mfp-zoom {
    cursor: pointer;
    cursor: -webkit-zoom-in;
    cursor: -moz-zoom-in;
    cursor: zoom-in
}

.mfp-auto-cursor .mfp-content {
    cursor: auto
}

.mfp-arrow,.mfp-close,.mfp-counter,.mfp-preloader {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}

.mfp-loading.mfp-figure {
    display: none
}

.mfp-hide {
    display: none!important
}

.mfp-preloader {
    color: #ccc;
    position: absolute;
    top: 50%;
    width: auto;
    text-align: center;
    margin-top: -.8em;
    left: 8px;
    right: 8px;
    z-index: 1044
}

.mfp-preloader a {
    color: #ccc
}

.mfp-preloader a:hover {
    color: #fff
}

.mfp-s-ready .mfp-preloader {
    display: none
}

.mfp-s-error .mfp-content {
    display: none
}

button.mfp-arrow,button.mfp-close {
    overflow: visible;
    cursor: pointer;
    background: 0 0;
    border: 0;
    -webkit-appearance: none;
    display: block;
    outline: 0;
    padding: 0;
    z-index: 1046;
    box-shadow: none;
    touch-action: manipulation
}

button::-moz-focus-inner {
    padding: 0;
    border: 0
}

.mfp-close {
    width: 44px;
    height: 44px;
    line-height: 44px;
    position: absolute;
    right: 0;
    top: 0;
    text-decoration: none;
    text-align: center;
    opacity: .65;
    padding: 0 0 18px 10px;
    color: #fff;
    font-style: normal;
    font-size: 28px;
    font-family: Arial,Baskerville,monospace
}

.mfp-close:focus,.mfp-close:hover {
    opacity: 1
}

.mfp-close:active {
    top: 1px
}

.mfp-close-btn-in .mfp-close {
    color: #333
}

.mfp-iframe-holder .mfp-close,.mfp-image-holder .mfp-close {
    color: #fff;
    right: -6px;
    text-align: right;
    padding-right: 6px;
    width: 100%
}

.mfp-counter {
    position: absolute;
    top: 0;
    right: 0;
    color: #ccc;
    font-size: 12px;
    line-height: 18px;
    white-space: nowrap
}

.mfp-iframe-holder {
    padding-top: 40px;
    padding-bottom: 40px
}

.mfp-iframe-holder .mfp-content {
    line-height: 0;
    width: 100%;
    max-width: 900px
}

.mfp-iframe-holder .mfp-close {
    top: -40px
}

.mfp-iframe-scaler {
    width: 100%;
    height: 0;
    overflow: hidden;
    padding-top: 56.25%
}

.mfp-iframe-scaler iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 8px rgba(0,0,0,.6);
    background: #000
}

img.mfp-img {
    width: auto;
    max-width: 100%;
    height: auto;
    display: block;
    line-height: 0;
    box-sizing: border-box;
    padding: 40px 0 40px;
    margin: 0 auto
}

.mfp-figure {
    line-height: 0
}

.mfp-figure:after {
    content: '';
    position: absolute;
    left: 0;
    top: 40px;
    bottom: 40px;
    display: block;
    right: 0;
    width: auto;
    height: auto;
    z-index: -1;
    box-shadow: 0 0 8px rgba(0,0,0,.6);
    background: #444
}

.mfp-figure small {
    color: #bdbdbd;
    display: block;
    font-size: 12px;
    line-height: 14px
}

.mfp-figure figure {
    margin: 0
}

.mfp-bottom-bar {
    margin-top: -36px;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    cursor: auto
}

.mfp-title {
    text-align: left;
    line-height: 18px;
    color: #f3f3f3;
    word-wrap: break-word;
    padding-right: 36px
}

.mfp-image-holder .mfp-content {
    max-width: 100%
}

.mfp-gallery .mfp-image-holder .mfp-figure {
    cursor: pointer
}

@media screen and (max-width: 800px) and (orientation:landscape),screen and (max-height:300px) {
    .mfp-img-mobile .mfp-image-holder {
        padding-left:0;
        padding-right: 0
    }

    .mfp-img-mobile img.mfp-img {
        padding: 0
    }

    .mfp-img-mobile .mfp-figure:after {
        top: 0;
        bottom: 0
    }

    .mfp-img-mobile .mfp-figure small {
        display: inline;
        margin-left: 5px
    }

    .mfp-img-mobile .mfp-bottom-bar {
        background: rgba(0,0,0,.6);
        bottom: 0;
        margin: 0;
        top: auto;
        padding: 3px 5px;
        position: fixed;
        box-sizing: border-box
    }

    .mfp-img-mobile .mfp-bottom-bar:empty {
        padding: 0
    }

    .mfp-img-mobile .mfp-counter {
        right: 5px;
        top: 3px
    }

    .mfp-img-mobile .mfp-close {
        top: 0;
        right: 0;
        width: 35px;
        height: 35px;
        line-height: 35px;
        background: rgba(0,0,0,.6);
        position: fixed;
        text-align: center;
        padding: 0
    }
}

@media all and (max-width: 900px) {
    .mfp-arrow {
        -webkit-transform:scale(.75);
        transform: scale(.75)
    }

    .mfp-arrow-left {
        -webkit-transform-origin: 0;
        transform-origin: 0
    }

    .mfp-arrow-right {
        -webkit-transform-origin: 100%;
        transform-origin: 100%
    }

    .mfp-container {
        padding-left: 6px;
        padding-right: 6px
    }
}

.mfp-arrow-left,.mfp-arrow-right {
    position: absolute;
    z-index: 8888;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    color: #343a40;
    background-color: #fff;
    width: 50px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    text-align: center
}

.mfp-arrow-left:after,.mfp-arrow-right:after {
    color: #000;
    font-size: 13px;
    font-family: icomoon!important;
    transition: all .25s
}

.mfp-arrow-left:hover:after,.mfp-arrow-right:hover:after {
    color: #444
}

.mfp-arrow-left {
    left: 25px
}

.mfp-arrow-left:after {
    content: "\e902"
}

.mfp-arrow-right {
    right: 25px
}

.mfp-arrow-right:after {
    content: "\e903"
}

.mfp-iframe-holder .mfp-close,.mfp-image-holder .mfp-close {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 99;
    color: #343a40;
    background-color: #fff;
    opacity: 1;
    width: auto;
    font-size: 24px;
    padding: 12px;
    cursor: pointer!important;
    width: auto;
    height: auto;
    line-height: 1;
    transition: all .3s
}

.mfp-close:active {
    top: 0
}

.mfp-close:hover {
    color: #444
}

.mfp-container {
    padding: 60px 120px
}

.mfp-bottom-bar,.mfp-counter {
    display: none
}

img.mfp-img {
    padding: 0
}

.mfp-figure figure {
    padding: 8px;
    background-color: #fff
}

.mfp-iframe-holder .mfp-content {
    padding: 8px;
    background-color: #fff
}

.mfp-iframe-scaler iframe {
    box-shadow: none!important
}

.mfp-ready .mfp-figure {
    opacity: 0
}

.mfp-zoom-in .mfp-figure,.mfp-zoom-in .mfp-iframe-holder .mfp-iframe-scaler {
    opacity: 0;
    -webkit-transition: all .3s ease-out;
    transition: all .3s ease-out;
    -webkit-transform: scale(.95);
    -ms-transform: scale(.95);
    transform: scale(.95)
}

.mfp-zoom-in .mfp-preloader,.mfp-zoom-in.mfp-bg {
    opacity: 0;
    -webkit-transition: all .3s ease-out;
    transition: all .3s ease-out
}

.mfp-zoom-in.mfp-image-loaded .mfp-figure,.mfp-zoom-in.mfp-ready .mfp-iframe-holder .mfp-iframe-scaler {
    opacity: 1;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1)
}

.mfp-zoom-in.mfp-ready .mfp-preloader,.mfp-zoom-in.mfp-ready.mfp-bg {
    opacity: .8
}

.mfp-zoom-in.mfp-removing .mfp-figure,.mfp-zoom-in.mfp-removing .mfp-iframe-holder .mfp-iframe-scaler {
    -webkit-transform: scale(.95);
    -ms-transform: scale(.95);
    transform: scale(.95);
    opacity: 0
}

.mfp-zoom-in.mfp-removing .mfp-preloader,.mfp-zoom-in.mfp-removing.mfp-bg {
    opacity: 0
}

.mfp-iframe-scaler {
    overflow: visible
}

.mfp-zoom-out-cur {
    cursor: auto
}

.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
    cursor: pointer
}

@media (max-width: 1599.98px) {
    .mfp-bottom-bar {
        padding:30px
    }
}

@media (max-width: 991.98px) {
    .mfp-bottom-bar {
        position:static;
        background-color: #fff;
        padding-top: 20px;
        margin-top: 0
    }

    .mfp-bottom-bar h3,.mfp-bottom-bar h6,.mfp-bottom-bar p {
        color: #444
    }

    .mfp-bottom-bar p {
        font-size: 14px
    }
}

@media (max-width: 991.98px) {
    .mfp-arrow-left,.mfp-arrow-right {
        font-size:12px;
        width: 50px;
        height: 50px
    }
}

@media (max-width: 767.98px) {
    .mfp-container {
        padding:40px 10px
    }

    .mfp-figure figure {
        padding: 38px 0;
        background-color: transparent
    }

    .mfp-image-holder .mfp-close {
        top: 8px;
        right: 5px;
        width: 30px;
        height: 30px
    }

    .mfp-arrow-left,.mfp-arrow-right {
        width: 30px;
        height: 30px;
        font-size: 8px
    }

    .mfp-arrow-left {
        left: 10px
    }

    .mfp-arrow-right {
        right: 10px
    }

    .mfp-iframe-holder .mfp-close,.mfp-image-holder .mfp-close {
        font-size: 14px;
        padding: 8px
    }
}
