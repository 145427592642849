@charset 'utf-8';

/*!
 * Bootstrap v5.0.1 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

// scss-docs-start import-stack
// Configuration
@import "functions";
@import "variables";
@import "mixins";
@import "utilities";

// Layout & components
@import "root";
@import "reboot";
@import "type";
@import "images";
@import "containers";
@import "grid";
@import "tables";
@import "forms";
@import "buttons";
@import "transitions";
@import "dropdown";
@import "button-group";
@import "nav";
@import "navbar";
@import "card";
@import "accordion";
@import "breadcrumb";
@import "pagination";
@import "badge";
@import "alert";
@import "progress";
@import "list-group";
@import "close";
@import "toasts";
@import "modal";
@import "tooltip";
@import "popover";
@import "carousel";
@import "spinners";
@import "offcanvas";

// Helpers
@import "helpers";

// Utilities
@import "utilities/api";
// scss-docs-end import-stack

/*!
 * Project:	      COMUP CMS
 * Version:	      1.0
 * Assigned to:   Comup.pl
 */

@import "vendor/jquery.mCustomScrollbar";
@import "vendor/dropzone";
@import "vendor/jquery.datetimepicker";
@import "vendor/jquery-ui";
@import "vendor/mfp";
@import "vendor/nv.d3.min";
@import "vendor/simplebar";
@import "vendor/swiper";
@import "vendor/timepicki";

@import "app/settings";
@import "app/unit";
@import "app/global";
@import "app/helpers";
@import "app/icomoon";
@import "app/typography";
@import "app/table";
@import "app/forms";
@import "app/tabs";
@import "app/pagination";
@import "app/layout";
@import "app/modal";
@import "app/widget";
@import "app/header";
@import "app/bredcrumbs";
@import "app/buttons";
@import "app/badge";
@import "app/sidebar";
@import "app/theme";

@import "vendor/custom";
